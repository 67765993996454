import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ScrollToAnchor mdxType="ScrollToAnchor" />
    <p>{`We try to design apps that feel at home on their respective platform, taking advantage of the unique functionality that exists within each ecosystem. At the same time, we lean on GitHub's design language of colors, iconography, copywriting, and visual design, to provide a coherent cross-platform experience to people.`}</p>
    <h2>{`GitHub`}</h2>
    <p>{`While we do our best to leverage platform technologies on Android and iOS, we also use components, colors, iconography, and other elements from GitHub's design infrastructure to provide understandable and cohesive experiences across all platforms.`}</p>
    <p><strong parentName="p">{`Recommended resources:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Primer Interface Guidelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/octicons/"
        }}>{`Octicons`}</a></li>
    </ul>
    <h2>{`Responsive web`}</h2>
    <p>{`The mobile apps complement GitHub's responsive web efforts. The web – and the value of the URL – remain a core part of GitHub's product philosophy. Because of this, we do our best to provide escape hatches from the apps to the mobile website whenever possible. We do this by including Share actions in the navigation bar whenever possible.`}</p>
    <img src="https://user-images.githubusercontent.com/1923260/87459988-6150b900-c5c9-11ea-8443-516286ecd133.png" alt="Native app header with a share icon" />
    <p>{`Conversely, many experiences can be better when we remove the URL and allow users to take advantage of a more gesture-based, fluid app experience. For example, when triaging notifications, it can often feel faster and easier to swipe notifications done, rather than tapping through them one at a time. Because these experiences can be so powerful, we also provide prompts in the mobile browser to open GitHub's native app.`}</p>
    <img src="https://user-images.githubusercontent.com/1923260/87459929-4b42f880-c5c9-11ea-887d-4a95814d3198.png" alt="Responsive web view of GitHub.com with a top banner to open in the mobile app" />
    <img src="https://user-images.githubusercontent.com/1923260/87461174-4121f980-c5cb-11ea-840b-6ca574b68825.png" alt="" role="presentation" width="100%" />
    <h2>{`Android`}</h2>
    <p>{`Our Android application leans on the `}<a parentName="p" {...{
        "href": "https://material.io/design"
      }}>{`Material Design Spec`}</a>{` to provide usable, understandable, and responsive applications.`}</p>
    <p><strong parentName="p">{`Recommended resources:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design"
        }}>{`Material Design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/components"
        }}>{`Material Components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/shape/about-shape.html#shaping-material"
        }}>{`About Material Shape`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/typography/the-type-system.html#type-scale"
        }}>{`The Type System`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/file/778763161265841481"
        }}>{`Material Baseline Figma Kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/file/787036571667088922"
        }}>{`Material Design Dark Theme Figma Kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=9NDLR3COU7Y"
        }}>{`How to Design a Dark Theme Using Material`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/sound/about-sound.html#principles"
        }}>{`Material Sound`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/motion/understanding-motion.html#principles"
        }}>{`Understanding Motion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/environment/surfaces.html#material-environment"
        }}>{`Material Surfaces`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/layout/understanding-layout.html#"
        }}>{`Understanding Layout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/navigation/understanding-navigation.html"
        }}>{`Understanding Navigation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://material.io/design/color/the-color-system.html#color-usage-and-palettes"
        }}>{`The Color System`}</a></li>
    </ul>
    <p>{`Be sure to explore past `}<a parentName="p" {...{
        "href": "https://design.google/library/io-2019-our-definitive-guide-design/"
      }}>{`Google I/O videos`}</a>{` to learn about designing great native Android applications.`}</p>
    <h2>{`iOS and iPadOS`}</h2>
    <p>{`Our iOS and iPadOS applications lean on the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/design/human-interface-guidelines/"
      }}>{`Human Interface Guidlines`}</a>{` to provide responsive, accessibly, and performant applications.`}</p>
    <p><strong parentName="p">{`Recommended resources:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/design/human-interface-guidelines/ios/overview/themes/"
        }}>{`iOS Human Interface Guidelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2020/10206/"
        }}>{`Design for iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2020/10205/"
        }}>{`Design with iOS pickers, menus, and actions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2020/10640/"
        }}>{`Design for the iPadOS pointer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/tech-talks/801/"
        }}>{`Designing for iPhone X`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2019/810/"
        }}>{`Designing Audio-Haptic Experiences`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2019/244/"
        }}>{`Visual Design and Accessibility`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2018/803/"
        }}>{`Designing Fluid Interfaces`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2018/802/"
        }}>{`Intentional Design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2018/210/"
        }}>{`Designing with Dark Mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/videos/play/wwdc2017/803/"
        }}>{`Designing Sound`}</a></li>
    </ul>
    <p>{`Be sure to explore past `}<a parentName="p" {...{
        "href": "https://developer.apple.com/videos/all-videos/?q=design"
      }}>{`WWDC session videos about design`}</a>{` to learn more about designing great mobile experiences on iOS and iPadOS.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      